<template>
  <div class="hero__section">
    <div class="analysis__block">
      <div class="analysis__heading">
        <h1>Анализы</h1>
        <div class="analysis__switch">
          <span>Срочно</span>
          <QuickSwitch />
        </div>
      </div>
      <div class="analysis__items">
        <div class="search__analysis">
          <input
            type="search"
            v-model="searchQuery"
            name=""
            id=""
            @keyup.enter="searchAndRedirect"
            placeholder="Поиск"
          />
          <transition name="fade">
            <button v-if="searchQuery.length > 0" @click="searchAndRedirect">
              Искать
            </button>
          </transition>
        </div>
        <div class="analysis__items-wrapper">
          <AnalysisCardMini
            v-for="(analysis, index) in analyses"
            :key="index"
            :title="analysis.title"
            :price="analysis.price"
          />
        </div>
      </div>
    </div>
    <div class="get__analysis-result">
      <h2>Получить результат</h2>
      <input type="text" placeholder="Номер заказа" />
      <div class="date-input-wrapper">
        <input
          type="text"
          name=""
          id=""
          placeholder="Дата рождения"
          onfocus="(this.type='date')"
          onblur="(this.type='text')"
        />
      </div>
      <button>Получить бланк</button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import AnalysisCardMini from "./AnalysisCardMini.vue";
import { useRouter } from "vue-router";
import QuickSwitch from "./QuickSwitch.vue";

export default {
  components: {
    AnalysisCardMini,
    QuickSwitch,
  },
  setup() {
    const analyses = ref([
      {
        title: "Общий анализ крови с микроскопией и СОЭ",
        price: { basic: "200₽", quick: "400₽" },
      },
      {
        title: "Биохимический анализ крови и мочи",
        price: { basic: "300₽", quick: "500₽" },
      },
      {
        title: "Анализ мочи общий",
        price: { basic: "150₽", quick: "350₽" },
      },
    ]);

    const searchQuery = ref("");
    const router = useRouter();

    const updateSearchQuery = () => {};

    const searchAndRedirect = () => {
      localStorage.setItem("searchQuery", searchQuery.value);
      localStorage.setItem("fromSearch", "true");
      router.push({ path: "/analysis" });
    };

    return { analyses, searchQuery, updateSearchQuery, searchAndRedirect };
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.hero__section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  padding: 32px 0 16px 0;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 576px) {
    padding: 32px 0 8px 0;
  }

  input::placeholder {
    color: #000027;
    opacity: 0.4;
  }

  .analysis__block {
    border-radius: 16px;
    border: 1px solid #e2e7ec;
    background: #f1f7f7;
    grid-column: span 2;

    display: flex;
    padding: 28px 24px 32px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 27px;

    @media (max-width: 768px) {
      grid-column: auto;
    }

    @media (max-width: 576px) {
      padding: 22px 16px 24px 16px;
      gap: 24px;
      border-radius: 12px;
    }

    .analysis__heading {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      h1 {
        color: #000027;
        font-family: "Inter";
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.56px;

        @media (max-width: 576px) {
          font-size: 24px;
        }
      }

      .analysis__switch {
        display: flex;
        flex-flow: nowrap row;
        gap: 8px;

        span {
          color: #000027;
          font-family: "Inter";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.4px;
        }
      }
    }

    .analysis__items {
      width: 100%;
      display: flex;
      flex-flow: wrap column;
      gap: 12px;

      .search__analysis {
        width: 100%;
        position: relative;

        button {
          cursor: pointer;
          position: absolute;
          top: 6.5px;
          bottom: 6.5px;
          right: 8px;
          padding: 12px 24px 13px 24px;
          border-radius: 9px;
          border: none;

          color: #fff;
          font-family: "Inter";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.16px;

          background-color: #0c7be5;
          transition: all 0.2s ease-in-out;

          @media (max-width: 576px) {
            font-size: 14px;
            padding: 12px 10px 11px 12px;
          }

          &:hover,
          :active {
            background-color: #0067d1;
          }

          &:active {
            transform: scale(0.98);
          }
        }
      }

      input {
        width: 100%;
        display: flex;
        padding: 18px 20px;
        align-items: flex-start;
        gap: 8px;

        border-radius: 12px;
        border: 1px solid #d8eff5;
        background: #fff;

        color: #000027;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.36px;

        @media (max-width: 576px) {
          padding: 16px 10px 16px 16px;
          font-size: 16px;
          border-radius: 10px;
        }
      }

      .analysis__items-wrapper {
        display: flex;
        gap: 12px;

        @media (max-width: 756px) {
          flex-flow: nowrap column;
          gap: 8px;
        }

        div {
          width: 100%;
        }
      }
    }
  }

  .get__analysis-result {
    display: flex;
    padding: 28px 24px 32px 24px;
    flex-flow: nowrap column;
    border-radius: 16px;
    border: 1px solid #e2e7ec;
    background: #f1f7f7;

    @media (max-width: 576px) {
      padding: 22px 16px 24px 16px;
      border-radius: 12px;
    }

    h2 {
      color: #000027;
      font-family: "Inter";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.48px;
      margin-bottom: 32px;

      @media (max-width: 576px) {
        font-size: 22px;
        margin-bottom: 24px;
      }
    }

    input[type="text"] {
      width: 100%;
      display: flex;
      padding: 18px 24px;
      gap: 8px;
      color: #000027;
      font-family: "Inter";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.36px;
      border-radius: 10px;
      border: 1px solid #e2e7ec;
      background: #fff;
      margin-bottom: 8px;

      @media (max-width: 576px) {
        padding: 16px 10px 16px 16px;
        font-size: 16px;
        border-radius: 10px;
      }
    }

    .date-input-wrapper {
      margin-bottom: 24px;

      @media (max-width: 576px) {
        border-radius: 10px;
      }

      input[type="date"] {
        display: flex;
        width: 100%;
        padding: 18px 18px 18px 24px;
        align-items: center;
        justify-content: space-between;
        height: 59px !important;
        gap: 8px;
        border-radius: 10px;
        border: 1px solid #e2e7ec !important;
        background: #fff !important;
        border: none;
        background: transparent;
        outline: none;
        flex-grow: 1;
        font-size: 18px;
        color: #000027;
        margin-bottom: 8px;

        @media (max-width: 576px) {
          padding: 0 !important;
          font-size: 16px;
          border-radius: 10px;
        }

        &::-webkit-calendar-picker-indicator {
          background: url("../assets/img/calendar.svg") no-repeat center center;
          color: transparent;
          opacity: 0.6;
          cursor: pointer;
        }

        &::-webkit-datetime-edit {
          color: #000027;
        }
      }
    }

    button {
      z-index: 3;
      cursor: pointer;
      padding: 18px 0px 19px 0px;
      color: #fff;
      font-family: "Inter";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-radius: 10px;
      border: none;
      background-color: #0c7be5;
      transition: all 0.2s ease-in-out;

      @media (max-width: 576px) {
        padding: 16px 0px 17px 0px;
        font-size: 16px;
      }

      &:hover,
      :active {
        background-color: #0067d1;
      }

      &:active {
        transform: scale(0.98);
      }
    }
  }
}
</style>
