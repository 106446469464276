<template>
  <div class="factoid">
    <div class="factoid__caption">{{ caption }}</div>
    <div class="factoid__indication">
      <div class="factoid__title">{{ title }}</div>
      <img
        src="@/assets/img/thunder.svg"
        alt="thunder.svg"
        :style="{ display: quick }"
      />
    </div>
    <div class="factoid__description">{{ description }}</div>
  </div>
</template>

<script>
export default {
  props: {
    caption: {
      type: String,
      default: "Caption",
    },
    indicationColor: {
      type: String,
      default: "#000027",
    },
    title: {
      type: String,
      default: "Factoid",
    },
    quick: {
      type: String,
      default: "none",
    },
    description: {
      type: String,
      default: "Short description",
    },
  },
};
</script>

<style lang="scss" scoped>
.factoid {
  .factoid__caption {
    opacity: 0.4;
    color: #000027;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.48px;
    margin-bottom: 8px;

    @media (max-width: 576px) {
      font-size: 18px;
      margin-bottom: 4px;
    }
  }

  .factoid__indication {
    display: flex;
    flex-flow: nowrap row;
    align-items: flex-start;
    color: var(--indication-color, #000027);

    img {
      top: 0px;
      right: 40%;

      @media (max-width: 576px) {
        height: 32px;
      }
    }
  }

  .factoid__title {
    font-family: "Inter";
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1.28px;
    margin-bottom: 64px;

    @media (max-width: 576px) {
      font-size: 48px;
      margin-bottom: 28px;
    }
  }

  .factoid__description {
    max-width: 277px;
    color: #000027;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.36px;

    @media (max-width: 576px) {
      font-size: 16px;
    }
  }
}
</style>
