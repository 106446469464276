<template>
  <div class="toggle-switch" :class="{ on: isOn }" @click="toggle">
    <img src="@/assets/img/thunder_switch.svg" alt="thunder_switch" />
    <div class="toggle-handle"></div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";

export default {
  name: "ToggleSwitch",
  setup() {
    const isOn = ref(false);

    // Load the state from localStorage when the component is mounted
    onMounted(() => {
      const savedState = localStorage.getItem("toggle-switch-state");
      if (savedState !== null) {
        isOn.value = JSON.parse(savedState);
      }
    });

    // Watch the isOn ref and save its state to localStorage whenever it changes
    watch(isOn, (newValue) => {
      localStorage.setItem("toggle-switch-state", JSON.stringify(newValue));
    });

    const toggle = () => {
      isOn.value = !isOn.value;
    };

    return {
      isOn,
      toggle,
    };
  },
};
</script>

<style lang="scss" scoped>
.toggle-switch {
  width: 40px;
  height: 24px;
  background-color: #ddd;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;

  img {
    position: absolute;
    top: 6px;
    left: 4px;
  }
}

.toggle-switch.on {
  background-color: #e74f48;
}

.toggle-handle {
  width: 18px;
  height: 18px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
  transition: left 0.3s;
}

.toggle-switch.on .toggle-handle {
  left: 19px;
}
</style>
