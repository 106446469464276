<template>
  <Navbar />
  <router-view />
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  components: {
    Navbar,
  },
  setup() {
    return {
      Navbar,
    };
  },
};
</script>

<style lang="scss">
*,
::after,
::before {
  box-sizing: border-box;
}

* {
  padding: 0;
  margin: 0;
  // transition: all .2s ease-in-out;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Unbounded:wght@200..900&display=swap");

:focus {
  outline: none;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-variant-ligatures: common-ligatures;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000027;
  font-family: "Inter", sans-serif;
}

.container {
  padding: 80px 64px 0px 64px;

  @media (max-width: 768px) {
    padding: 56px 32px 0 32px;
  }

  @media (max-width: 576px) {
    padding: 48px 16px 0 16px;
  }
}

a {
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.6 !important;
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.disabled__element {
  pointer-events: none;
  opacity: 0.4;
}
</style>
