<template>
  <div class="licence__section">
    <div class="heading__wrapper">
      <h3>
        Наша лаборатория является партнером крупнейших лабораторий России,
        поэтому мы&nbsp;готовы предложить самый широкий спектр выполняемых
        анализов.
      </h3>
      <div class="open__modal" @click="openModal">
        <span>Открыть лицензию</span>
        <img src="@/assets/img/plus.svg" alt="plus" />
      </div>
    </div>
    <div class="our__partners">
      <p>Наши партнеры</p>
      <img class="medlab" src="@/assets/img/medlab.png" alt="medlab" />
      <img class="invitro" src="@/assets/img/invitro.svg" alt="invitro" />
      <img class="gemotest" src="@/assets/img/gemotest.svg" alt="gemotest" />
      <div class="elixir__wrapper">
        <img class="elixir" src="@/assets/img/elixir.svg" alt="elixir" />
        <span>Эликсир-Д</span>
      </div>
    </div>
  </div>
  <Modal ref="modalRef" />
</template>

<script>
import Modal from "@/components/Modal.vue";
import { ref } from "vue";

export default {
  components: {
    Modal,
  },
  setup() {
    const modalRef = ref(null);

    const openModal = () => {
      modalRef.value.show = true;
    };

    return {
      modalRef,
      openModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.licence__section {
  display: flex;
  padding: 0px 0 112px 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 112px;

  @media (max-width: 768px) {
    gap: 72px;
  }

  @media (max-width: 576px) {
    padding: 0px 0 64px 0;
  }

  .heading__wrapper {
    border-top: 1px solid #ecf1f6;
    padding-top: 40px;
    width: 100%;
    display: flex;
    flex-flow: nowrap row;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-flow: nowrap column;
      gap: 40px;
    }

    @media (max-width: 576px) {
      gap: 32px;
    }

    h3 {
      max-width: 710px;
      color: #000027;
      font-family: "Inter";
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 135%; /* 37.8px */
      letter-spacing: -0.56px;

      @media (max-width: 576px) {
        font-size: 24px;
      }
    }
  }

  .open__modal {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    display: flex;
    flex-flow: nowrap row;
    align-items: flex-start;
    gap: 8px;

    // @media (max-width: 576px) {
    //   align-items: center;
    //   font-size: 20px;
    // }

    span {
      margin-top: 2px;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  .our__partners {
    display: flex;
    flex-flow: wrap row;

    @media (max-width: 576px) {
      flex-flow: wrap column;
    }

    p {
      flex: 2 1 100%;
      color: #000027;
      font-family: "Inter";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.4px;
      opacity: 0.4;
      margin-bottom: 40px;
    }

    .medlab {
      height: 48px;

      @media (max-width: 576px) {
        width: 76px;
        height: 48px;
      }
    }

    .invitro {
      @media (max-width: 576px) {
        height: 28px;
      }
    }

    .gemotest {
      @media (max-width: 576px) {
        width: 184px;
        height: 19px;
        margin-bottom: 32px;
      }
    }

    .elixir__wrapper {
      display: flex;
      flex-flow: nowrap row;
      align-items: center;
      gap: 16px;
      color: #2f9e40;
      font-size: 30px;
      font-weight: 700;

      .elixir {
        margin-left: 56px;
        height: 40px;
        @media (max-width: 576px) {
          height: 32px;
          margin-left: 0;
        }
      }
    }

    .medlab,
    .invitro {
      margin-right: 56px;

      @media (max-width: 576px) {
        margin-right: none;
        margin-bottom: 32px;
      }
    }
  }
}
</style>
