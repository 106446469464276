<template>
  <div class="navbar">
    <router-link to="/" class="logo__home" @click="closeMenu">
      <img src="@/assets/img/Logo.jpg" alt="Logo" />
    </router-link>
    <div class="pages__wrapper">
      <router-link
        to="/"
        :class="{ active: $route.name === 'Home' }"
        @click="closeMenu"
        >Главная</router-link
      >
      <router-link
        to="analysis"
        :class="{ active: $route.name === 'Analysis' }"
        @click="closeMenu"
        >Анализы</router-link
      >
    </div>
    <div class="account">
      <router-link to="/checkout" @click="closeMenu">
        <img src="@/assets/img/checkout.svg" alt="checkout" />
      </router-link>
      <router-link to="/login" class="login" @click="closeMenu">
        <span>Войти</span>
        <img src="@/assets/img/login.svg" alt="login" />
      </router-link>
      <button class="mobile__menu" @click="toggleMenu">
        <img
          :src="isMenuOpen ? closeIcon : menuIcon"
          :alt="isMenuOpen ? 'close' : 'menu'"
        />
      </button>
    </div>
  </div>
  <Menu :show="isMenuOpen" @closeMenu="isMenuOpen = false" />
</template>

<script>
import { ref, onMounted } from "vue";
import Menu from "@/components/Menu.vue";
import { useRouter } from "vue-router";

const menuIcon = require("@/assets/img/menu.svg");
const closeIcon = require("@/assets/img/close_24x24.svg");

export default {
  components: {
    Menu,
  },
  setup() {
    const isMenuOpen = ref(false);
    const router = useRouter();

    function toggleMenu() {
      isMenuOpen.value = !isMenuOpen.value;
    }

    function closeMenu() {
      isMenuOpen.value = false;
    }

    onMounted(() => {
      router.beforeEach((to, from, next) => {
        closeMenu();
        next();
      });
    });

    return {
      isMenuOpen,
      toggleMenu,
      closeMenu,
      menuIcon,
      closeIcon,
    };
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  padding: 9px 64px 13px 64px;
  z-index: 1000;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: nowrap row;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(32px);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;

  @media (max-width: 768px) {
    padding: 9px 48px 13px 48px;
  }

  @media (max-width: 576px) {
    padding: 9px 24px 4px 24px;
  }

  a {
    color: #000027;
    text-decoration: none;
  }

  .logo__home {
    img {
      height: 52px;

      @media (max-width: 576px) {
        height: 40px;
      }
    }
  }

  .pages__wrapper {
    display: flex;
    flex-flow: nowrap row;
    margin-left: auto;
    margin-right: auto;
    gap: 28px;
    transition: all 0.2s ease-in-out;

    @media (max-width: 576px) {
      display: none;
    }

    a {
      opacity: 0.4;
      transition: all 0.2s ease-in-out;

      &:hover {
        opacity: 0.6;
      }
    }

    .active {
      opacity: 1;
    }
  }

  .account {
    display: flex;
    flex-flow: nowrap row;
    gap: 24px;

    @media (max-width: 576px) {
      gap: 16px;
    }
  }

  .login {
    span {
      @media (max-width: 576px) {
        display: none;
      }
    }
    img {
      display: none;

      @media (max-width: 576px) {
        display: block;
      }
    }
  }

  .mobile__menu {
    display: none;
    padding: 0;
    border: none;
    background: none;

    @media (max-width: 576px) {
      display: block;
    }
  }
}
</style>
