<template>
  <div class="analysis__card">
    <h4>{{ title }}</h4>
    <div class="analysis__price">
      <div class="thunder">
        <img src="@/assets/img/thunder18x18.svg" alt="thunder18x18" />
        <div class="analysis__price-quick">{{ price.quick }}</div>
      </div>
      <div class="analysis__price-basic">{{ price.basic }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Analysis title",
    },
    price: {
      type: Object,
      default: () => ({
        basic: "0.00₽",
        quick: "0.00₽",
      }),
    },
  },
  setup() {},
};
</script>

<style lang="scss" scoped>
.analysis__card {
  display: flex;
  padding: 22px 20px 28px 20px;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  border-radius: 12px;
  border: 1px solid #d8eff5;
  background: #fff;

  h4 {
    overflow: hidden;
    color: #000027;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.36px;
  }

  .analysis__price {
    display: flex;
    flex-flow: wrap row;
    gap: 6px;
    align-items: flex-end;

    .thunder {
      display: flex;
      flex-flow: nowrap row;

      .analysis__price-quick {
        overflow: hidden;
        color: #0c7be5;
        text-overflow: ellipsis;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.36px;
      }
    }

    .analysis__price-basic {
      opacity: 0.4;
      overflow: hidden;
      color: #000027;
      text-overflow: ellipsis;
      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.28px;
    }
  }
}
</style>
