<template>
  <swiper
    :slides-per-view="1"
    :space-between="10"
    pagination
    :navigation="true"
    class="mySwiper"
    :autoplay="{ delay: 5000, disableOnInteraction: false }"
  >
    <swiper-slide v-for="(slide, index) in currentSlides" :key="index">
      <img :src="slide" alt="Slide image" />
    </swiper-slide>
  </swiper>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";

SwiperCore.use([Pagination, Navigation, Autoplay]);

export default defineComponent({
  name: "MySlider",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const isMobile = ref(window.innerWidth <= 576);

    const slidesDesktop = ["https://ealab.pro/img/promotion/promo1.jpg"];

    const slidesMobile = ["https://ealab.pro/img/promotion/promo1_mobile.jpg"];

    const currentSlides = computed(() =>
      isMobile.value ? slidesMobile : slidesDesktop
    );

    window.addEventListener("resize", () => {
      isMobile.value = window.innerWidth <= 768;
    });

    return {
      currentSlides,
    };
  },
});
</script>

<style lang="scss" scoped>
.mySwiper {
  width: 100%;
  height: 100%;
  /* height: 35.556vw; */
  margin-bottom: 56px;

  @media (max-width: 768px) {
    margin-bottom: 48px;
  }

  @media (max-width: 576px) {
    border-radius: 12px;
    margin-bottom: 48px;
  }
}

.mySwiper {
  // background: #e2e7ec;
  border-radius: 16px;
}

.mySwiper img {
  width: 100%;
  height: auto;
  border-radius: 16px;
}
</style>
