<template>
  <transition name="menu-fade" @after-leave="$emit('closeMenu')">
    <div class="menu__wrapper" v-if="isVisible" @click="handleClose">
      <div class="menu__section" @click.stop>
        <router-link to="/" @click="handleClose">Главная</router-link>
        <router-link to="/analysis" @click="handleClose">Анализы</router-link>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref, watch } from "vue";

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["closeMenu"],
  setup(props, { emit }) {
    const isVisible = ref(props.show);

    watch(
      () => props.show,
      (newVal) => {
        isVisible.value = newVal;
      }
    );

    function handleClose() {
      isVisible.value = false;
    }

    watch(isVisible, (newVal) => {
      if (!newVal) {
        setTimeout(() => {
          emit("closeMenu");
        }, 300);
      }
    });

    return {
      isVisible,
      handleClose,
    };
  },
};
</script>

<style lang="scss" scoped>
.menu__wrapper {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  transition: opacity 0.3s ease;
}

.menu-fade-enter-active,
.menu-fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.menu-fade-enter-from,
.menu-fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.menu__section {
  width: 100%;
  display: flex;
  padding: 81.5px 24px 40px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  background-color: #fff;
  border-radius: 0px 0px 12px 12px;
  box-shadow: 0px 4px 44px 0px rgba(0, 0, 39, 0.25);
  transform: translateY(0);
  transition: transform 0.3s ease;

  a {
    width: 100%;
    color: #000027;
    font-family: "Inter";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.56px;
  }
}
</style>
