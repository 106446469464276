<template>
  <div class="container">
    <Hero />
    <PromotionSlider />
    <About />
    <Licence />
  </div>
  <Footer />
</template>

<script>
// @ is an alias to /src
import PromotionSlider from "@/components/PromotionSlider.vue";
import Hero from "@/components/Hero.vue";
import About from "@/components/About.vue";
import Licence from "@/components/Licence.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Hero,
    About,
    PromotionSlider,
    Licence,
    Footer,
  },
  setup() {},
};
</script>
