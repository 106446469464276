<template>
  <div class="contacts__section">
    <div class="address__wrapper">
      <div class="address__caption">Адрес</div>
      <h3 class="address__title">Чайковский, Приморский<br />бульвар 32/1</h3>
    </div>
    <div class="contact__information">
      <div class="contact__phone">
        <p>Телефон</p>
        <a href="tel:+73424148828">+7 342 414-88-28</a>
      </div>
      <div class="contact__email">
        <p>Почта</p>
        <a href="mailto:info@ealab.pro">info@ealab.pro</a>
      </div>
      <div class="contact__website">
        <p>Сайт</p>
        <a href="/">www.ealab.pro</a>
      </div>
      <div class="contact__work-hours">
        <p>Режим работы</p>
        <div>Пн–Пт: 7:30 — 11:00 <br />Выходные: 9:00 — 11:00</div>
      </div>
    </div>
    <div class="official__information">
      <div class="official__copyright">ООО «Медсервис», {{ currentYear }}</div>
      <div class="official__description">
        <span>ИНН 5959001888</span>
        <span>ОГРН 1185958026100</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped>
.contacts__section {
  padding: 40px 64px 0px 64px;
  background: #e2f9ff;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 104px;
  column-gap: 24px;

  @media (max-width: 768px) {
    padding: 40px 32px 0 32px;
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 576px) {
    padding: 24px 16px 0 16px;
    gap: 0;
  }

  .address__wrapper {
    @media (max-width: 768px) {
      grid-column: span 2;
    }

    .address__caption {
      opacity: 0.4;
      color: #000027;
      font-family: "Inter";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.4px;
      margin-bottom: 16px;

      @media (max-width: 576px) {
        font-size: 16px;
        margin-bottom: 12px;
      }
    }

    .address__title {
      color: #000027;
      font-family: "Inter";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.8px;

      @media (max-width: 576px) {
        font-size: 28px;
        margin-bottom: 16px;
      }
    }
  }

  .contact__information {
    margin-top: 24px;
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 104px;
    row-gap: 56px;

    @media (max-width: 576px) {
      gap: 32px;
      grid-template-columns: repeat(1, 1fr);
      margin-bottom: 88px;
    }

    p {
      opacity: 0.4;
      color: #000027;
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.32px;
      margin-bottom: 8px;

      @media (max-width: 576px) {
        font-size: 16px;
        margin-bottom: 8px;
      }
    }

    a {
      text-decoration: none;
      color: #000027;
      font-family: "Inter";
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 33.6px */
      letter-spacing: -0.56px;

      @media (max-width: 576px) {
        font-size: 24px;
      }
    }
  }

  .contact__work-hours {
    div {
      color: #000027;
      font-family: "Inter";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 21.6px */
      letter-spacing: -0.36px;

      @media (max-width: 576px) {
        font-size: 18px;
      }
    }
  }

  .official__information {
    padding: 10px 0 16px 0;
    grid-column: span 2;
    border-top: 1px solid rgba(0, 0, 39, 0.1);
    background: #e2f9ff;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;

    @media (max-width: 768px) {
      display: flex;
      flex-flow: nowrap row;
      justify-content: space-between;
    }

    @media (max-width: 576px) {
      flex-flow: nowrap column;
      gap: 24px;
    }

    .official__description {
      display: flex;
      flex-flow: nowrap row;
      gap: 48px;
      white-space: nowrap;

      @media (max-width: 768px) {
        gap: 24px;
      }

      @media (max-width: 576px) {
        gap: 8px;
        flex-flow: nowrap column;
      }
    }
  }
}
</style>
