<template>
  <Teleport to="body">
    <VueFinalModal v-model="show" :class="['my-modal']">
      <div class="modal-content">
        <img src="@/assets/licence/licence.jpg" alt="licence" />
      </div>
      <button class="close__button" @click="closeModal">
        <img src="@/assets/img/close.svg" alt="close" />
      </button>
    </VueFinalModal>
  </Teleport>
</template>

<script>
import { ref } from "vue";
import { VueFinalModal } from "vue-final-modal";

export default {
  props: {
    image: {
      type: String,
      default: require("@/assets/licence/licence.jpg"),
    },
  },
  components: { VueFinalModal },
  setup() {
    const show = ref(false);

    const closeModal = () => {
      show.value = false;
    };

    return {
      show,
      closeModal,
    };
  },
};
</script>

<style lang="scss">
.my-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  transition: all 0.2s ease-in-out;

  img {
    height: 90vh;

    @media (max-width: 768px) {
      height: 80vh;
    }

    @media (max-width: 576px) {
      height: 65vh;
    }
  }
}

.close__button {
  cursor: pointer;
  width: 64px;
  height: 64px;
  display: flex;
  width: 64px;
  height: 64px;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(32px);
  border: none;
  position: absolute;
  top: 40px;
  right: 40px;
  transition: all 0.2s ease-in-out;

  @media (max-width: 768px) {
    top: 16px;
    right: 16px;
  }

  @media (max-width: 576px) {
    width: 40px;
    height: 40px;
  }

  img {
    height: 32px;

    @media (max-width: 576px) {
      height: 20px;
    }
  }

  &:hover,
  :focus {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: scale(0.98);
    background-color: rgba(0, 0, 0, 0.4);
  }
}
</style>
