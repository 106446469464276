<template>
  <div class="about__section">
    <div class="about__heading">
      <div class="about__caption">О нас</div>
      <h2 class="about__title">
        Современная лаборатория &laquo;Экспресс анализы&raquo; была создана
        в&nbsp;2018 году и&nbsp;на&nbsp;сегодняшний день является первой частной
        лабораторией в&nbsp;городе Чайковский
      </h2>
    </div>
    <div class="about__analysis">
      <div class="about__analysis-item">
        <h3>Общий анализ крови с микроскопией и СОЭ</h3>
        <img src="@/assets/img/staroflife.svg" alt="staroflife.svg" />
      </div>
      <div class="about__analysis-item">
        <h3>Общий анализ мочи</h3>
        <img src="@/assets/img/staroflife.svg" alt="staroflife.svg" />
      </div>
      <div class="about__analysis-item">
        <h3>Биохимический анализ крови и мочи</h3>
        <img src="@/assets/img/staroflife.svg" alt="staroflife.svg" />
      </div>
    </div>
  </div>
  <div class="factoid__wrapper">
    <Factoid
      caption="Режим"
      :style="{ '--indication-color': '#E74F48' }"
      title="Срочно"
      quick="block"
      description="Основной и&nbsp;самый частый набор методик"
    />
    <Factoid
      caption="Не более"
      :style="{ '--indication-color': '#0C7BE5' }"
      title="3 часов"
      description="Cрок получения результата с&nbsp;момента забора"
    />
  </div>
</template>

<script>
import Factoid from "./Factoid.vue";

export default {
  components: {
    Factoid,
  },
  setup() {},
};
</script>

<style lang="scss" scoped>
.about__section {
  display: flex;
  padding: 24px 0 0px 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 112px;

  @media (max-width: 768px) {
    padding: 16px 0 0px 0;
  }

  @media (max-width: 576px) {
    gap: 64px;
  }

  .about__title {
    max-width: 928px;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 43.2px */
    letter-spacing: -0.72px;

    @media (max-width: 576px) {
      font-size: 24px;
    }
  }

  .about__caption {
    margin-bottom: 24px;
    color: #000027;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.48px;
    opacity: 0.4;

    @media (max-width: 576px) {
      font-size: 16px;
      margin-bottom: 12px;
    }
  }

  .about__analysis {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;

    @media (max-width: 768px) {
      width: 100%;
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .about__analysis-item {
    display: flex;
    padding: 32px 24px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 110px;
    border-radius: 16px;
    border: 1px solid #d8eff5;
    background: #e2f9ff;

    @media (max-width: 576px) {
      padding: 24px 16px;
      gap: 32px;
    }

    h3 {
      overflow: hidden;
      color: #000027;
      text-overflow: ellipsis;
      font-family: "Inter";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.48px;

      @media (max-width: 576px) {
        font-size: 20px;
      }
    }

    img {
      @media (max-width: 576px) {
        height: 32px;
      }
    }
  }
}

.factoid__wrapper {
  padding: 80px 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 56px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 80px 16px;
  }

  @media (max-width: 576px) {
    padding: 40px 16px;
    grid-template-columns: repeat(1, 1fr);
    gap: 64px;
  }
}
</style>
